import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="text-secondary small text-center">
          Copyright 2024-2025 by Travelebrium
        </div>
      </div>
    </footer>
  );
};

export default Footer;
