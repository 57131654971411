import React from "react";

const HomeTestimonials = () => {
  return (
    <div className="home-testimonials">
      <div className="container">
        <h2>Testimonials</h2>
        <h4>
          Listen to what our awesome clients have to say about their trips with
          Travelebrium. Hear their experiences and get inspired for your next
          adventure.
        </h4>
        <div className="row">
          <div className="col-lg-4">
            <div className="shadow-box">
              <p>
                We have many options but we chose Travelebrium because the
                response time is hardly 2-3 minutes. We had sent the same
                inquiry to another agent and they did not revert for more than
                24 hours.
              </p>
              <h3>A private equity firm in India</h3>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <p>
                You answer calls at 4 in the morning. Our team is very
                comfortable with Travelebrium team
              </p>
              <h3>A leading private equity firm</h3>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <p>
                You offer many services along with travel like corporate
                gifting, event management. We don't have to co-ordinate with
                multiple vendors. We get one stop shop.
              </p>
              <h3>A leading financial services firm</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonials;
