import React from "react";
import TitleBg from "../assets/images/contact-sceen-bg.png";

const Contact = () => {
  return (
    <div className="inner-page">
      <div className="inner-page-title">
        <div className="container">
          <h1>Contact Us</h1>
        </div>
        <img src={TitleBg} alt="Contact Us" />
      </div>

      <div className="contact">
        <div className="container">
          <div>
            <h3>Contact : +91 9833568071</h3>
            <h3>
              Email :{" "}
              <a href="mailto:ravip@travelebrium.com">ravip@travelebrium.com</a>
            </h3>
            <h3>Address :</h3>
            <h5 className="mt-4" style={{ lineHeight: "28px" }}>
              Home Unit 105 First Floor, Sahar Cargo
              <br />
              VM Shah Road, JB Nagar Chakala
              <br />
              Andheri (East), Mumbai 400099
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
