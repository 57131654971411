import React from "react";
import Icon1 from "../assets/images/corporate-1.jpg";
import Icon2 from "../assets/images/corporate-1.jpeg";
import Icon3 from "../assets/images/corporate-3.jpg";
import Icon4 from "../assets/images/corporate-4.jpg";
import Icon5 from "../assets/images/corporate-5.jpg";
import Icon6 from "../assets/images/corporate-6.jpg";

const HomeCorporate = () => {
  return (
    <div className="home-corporate">
      <div className="container">
        <h2>Corporate Services</h2>
        <h4>
          We are premier event planning and management Company. We are dedicated
          to creating surpass your expectations that leave a lasting impression
          anywhere in the world. With our comprehensive range of services, we
          take care of every aspect of your event, ensuring a seamless and
          stress-free experience from start to finish.
        </h4>
        <div className="row">
          <div className="col-lg-4">
            <div className="shadow-box">
              <img src={Icon1} alt="Air Ticketing" />
              <h3>Corporate Events</h3>
              <p>
                We specialize in creating exceptional corporate events that
                inspire, engage and leave a lasting impact. Whether you're
                organizing a conference, gala dinner, Investor meet,
                team-building retreat or product launch, We have the expertise
                to make your event a resounding success.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <img src={Icon2} alt="Hotel Booking" />
              <h3>Corporate Gifting</h3>
              <p>
                We specialize in curating and delivering thoughtful and
                high-quality corporate gifts that leave a lasting impression. We
                help you strengthen business relationships, express gratitude,
                and enhance your brand presence through the power of gifting.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <img src={Icon3} alt="Visa Application" />
              <h3>Celebrations</h3>
              <p>
                We understand the importance of celebrating life's special
                moments. We go above and beyond to ensure that your celebration
                is a reflection of your dreams and aspirations. Let us take care
                of the intricate details and transform your vision into a
                remarkable celebration that will be cherished forever.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <img src={Icon4} alt="Car Hire" />
              <h3>Exhibitions</h3>
              <p>
                We are passionate about creating impactful exhibitions that
                inspire, educate, and entertain. With our meticulous planning,
                creative designs and exceptional execution with extensive
                network and well organized team, we ensure that your exhibition
                stands out and delivers on its objectives.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <img src={Icon5} alt="Travel Insurance" />
              <h3>Celebrity Management</h3>
              <p>
                We specialize in connecting brands, organizations, and events
                with top-tier celebrities and influencers to create impactful
                collaborations and elevate your brand presence. We help you
                navigate the world of celebrity endorsements, appearances, and
                partnerships to achieve your marketing and promotional goals.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow-box">
              <img src={Icon6} alt="Meet & Greet" />
              <h3>Brand Endorsement</h3>
              <p>
                We are passionate about helping businesses create, manage, and
                elevate their brand presence. Let us be your trusted partner in
                brand management, empowering you to build a strong, recognizable
                brand that resonates with your target audience and drives
                long-term success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCorporate;
