import React from "react";
import BannerImage from "../assets/images/banner-img.png";

const HomeBanner = () => {
  return (
    <div className="home-banner">
      <div className="container">
        <div>
          <h1>
            Travel management <br />
            across the <span>Globe</span>
          </h1>
        </div>
      </div>

      <img src={BannerImage} alt="" />
    </div>
  );
};

export default HomeBanner;
