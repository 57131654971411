import React from "react";
import TitleBg from "../assets/images/about-screen-bg.webp";
import Team1 from "../assets/images/team-1.png";
import Team2 from "../assets/images/team-2.png";
import Team3 from "../assets/images/team-3.png";
import Team4 from "../assets/images/team-4.png";

const About = () => {
  return (
    <div className="inner-page">
      <div className="inner-page-title">
        <div className="container">
          <h1>About Us</h1>
        </div>
        <img src={TitleBg} alt="About Us" />
      </div>

      <div className="pt-5 pb-5">
        <div className="container">
          <h4>
            Incorporated in 2011, Travelebrium is a Mumbai-based travel company
            specializing in Corporate travel. Our team comprises of seasoned
            professionals who come with tremendous management, operations and
            technology experience. This experience reflects in the way we handle
            complex travel and also the range of complexities that come with
            such endeavors.
          </h4>

          <div className="about-team">
            <h2>Our Team</h2>
            <div className="row">
              <div className="col-lg-3">
                <div className="shadow-box mb-5">
                  <img src={Team1} alt="Aditya Darooka" />
                  <h3>Aditya Darooka</h3>
                  <h5>CEO</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="shadow-box mb-5">
                  <img src={Team2} alt="Aditya Darooka" />
                  <h3>Aarti Tibrewala Darooka</h3>
                  <h5>COO</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="shadow-box mb-5">
                  <img src={Team3} alt="Aditya Darooka" />
                  <h3>Ravindra Pandey</h3>
                  <h5>Operations Manager</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="shadow-box mb-5">
                  <img src={Team4} alt="Aditya Darooka" />
                  <h3>Kartikeyan.R</h3>
                  <h5>Business Development</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
