import React, { useState } from "react";
import GottaholidayLogo from "../assets/images/Travelebrium-logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobile, setMobile] = useState(false);
  const handleMobileMenu = () => {
    setMobile(!isMobile);
  };

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-3 d-flex align-items-center">
            <Link to="/" className="logo">
              <img src={GottaholidayLogo} alt="gottaholiday.com" />
            </Link>
          </div>
          <div className="col-9 d-flex justify-content-end align-items-center">
            <button
              className="mobile-menu-btn d-none"
              onClick={handleMobileMenu}
            >
              <span className="material-symbols-outlined">|||</span>
            </button>
            <div className={"navbar" + (isMobile ? " show-mobile-menu" : "")}>
              <ul className="list-unstyled p-0 m-0 d-flex align-items-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                {/* <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/events">Events</Link>
                </li> */}
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
