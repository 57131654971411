import React from "react";
import HomeBanner from "../components/home-banner";
import HomeTestimonials from "../components/home-testimonials";
import HomeAbout from "../components/home-about";
import HomeServices from "../components/home-services";
import HomeCorporate from "../components/home-corporate";
import HomeEvents from "../components/home-events";

const Home = () => {
  return (
    <div className="home">
      <HomeBanner />
      <HomeServices />
      <HomeAbout />
      <HomeCorporate />
      <HomeTestimonials />
      {/* <HomeEvents /> */}
    </div>
  );
};

export default Home;
