import React from "react";

const HomeAbout = () => {
  return (
    <div className="home-about">
      <div className="container position-relative">
        <h2>About Travelebrium</h2>
        <p>
          At Travelebrium, we understand that travel is not just about the
          destination, but the journey itself. That is why we take great care in
          creating personalized travel experiences tailored to your unique
          preferences, ensuring that every aspect of your journey is
          exceptional. With over a decade of experience in the travel industry,
          we have built a reputation for consistently delivering high-quality,
          reliable services to every corner of the globe. Our team of travel
          experts is passionate about crafting journeys that will exceed your
          expectations and leave you with unforgettable memories to last a
          lifetime.
        </p>
      </div>
    </div>
  );
};

export default HomeAbout;
