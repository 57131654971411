import React from "react";
import Icon1 from "../assets/images/services-icon-1.png";
import Icon2 from "../assets/images/services-icon-2.png";
import Icon3 from "../assets/images/services-icon-3.png";
import Icon4 from "../assets/images/services-icon-4.png";
import Icon5 from "../assets/images/services-icon-5.png";
import Icon6 from "../assets/images/services-icon-6.png";
import Icon7 from "../assets/images/services-icon-7.png";
import Icon8 from "../assets/images/services-icon-8.png";

const HomeServices = () => {
  return (
    <div className="home-services">
      <div className="container">
        <h2>Services</h2>
        <h4>
          Travelebrium was founded with a vision to provide affordable,
          high-quality travel services to our customers. We understand that
          everyone's travel needs are different, which is why we are committed
          to providing customized travel packages that cater to each client's
          unique requirements.
        </h4>

        <div className="row">
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon1} alt="Air Ticketing" />
              <h3>Air Ticketing</h3>
              <p>
                We offer the lowest market fare available at any point of time
                for your preferred itinerary.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon2} alt="Hotel Booking" />
              <h3>Hotel Booking</h3>
              <p>
                Tie-ups with a wide range of hotels from budget to luxury.
                Anywhere in the World Competitive Rates Seamless bookings and
                payments
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon3} alt="Visa Application" />
              <h3>Visa Application</h3>
              <p>
                We apply for travel visas on behalf of our clients. We help them
                during the documentation process so that they don't have to
                worry about the complex paper work.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon4} alt="Car Hire" />
              <h3>Car Hire</h3>
              <p>
                We arrange for both self-driven and chauffeur-driven car hire
                services anywhere in the world.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon5} alt="Travel Insurance" />
              <h3>Travel Insurance</h3>
              <p>
                While planning a trip abroad, it becomes a necessity to remain
                safe, We help you with the travel insurance.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon6} alt="Meet & Greet" />
              <h3>Meet & Greet</h3>
              <p>
                we understand that the key to a successful trip is often found
                in the details. That's why we offer our Meet & Greet service,
                which provides you with a personalized, hassle-free travel
                experience.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon7} alt="MICE" />
              <h3>MICE</h3>
              <p>
                Meetings, Incentives, Conventions, and Events. Group Bookings &
                Tour Management Logistics management & Social Event Handling
                Team Building & Corporate social responsibility
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="shadow-box">
              <img src={Icon8} alt="Foreign Exchange" />
              <h3>Foreign Exchange</h3>
              <p>
                We help you obtain the currency of the country you wish to
                travel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
