import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./screens/home";
import About from "./screens/about";
import Contact from "./screens/contact";

const App = () => {
  return (
    <React.Fragment>
      <Header />
      <div style={{ minHeight: "calc(100vh - 162px)" }}>
        <Routes>
          <Route key={1} path="/" element={<Home />} />
          <Route key={2} path="/about-us" element={<About />} />
          <Route key={3} path="/contact-us" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default App;
